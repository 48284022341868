* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --theme-blue: #24a0ed;
    --error-color: #f44336;
    --header-bg-color: #181f30;
    --body-bg-color: #1c2234;
    --main-container-padding: 0px 100px;
    --font-gradient: -webkit-linear-gradient(45deg, #696eff 35%, #60efff, #f7acff 60.16%);
    --footer-bg-color: #0000;
}

/* general css */
.main-container {
    min-height: calc(100vh - 100px);
}

.input-group {
    margin-bottom: 20px;
}

.input-group input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    outline: none;
    font-size: 15px;
}

.error-message {
    color: var(--error-color);
}

.btn {
    padding: 10px 15px;
    width: 100%;
    /* to control the width with px kindly wrap the button into <div> and apply*/
    border: 0;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
}

/* header */
.header {
    background-color: var(--header-bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--main-container-padding);
    padding-top: 20px;
    padding-bottom: 20px;
}

.header .logo {
    width: 150px;
}

.header .logo p {
    text-align: center;
}

.header-profile-section {
    background-color: var(--body-bg-color);
    border-radius: 20px;
    min-width: 155px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-profile-section .profile-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.header-profile-section .user-name {
    color: #fff;
    margin-left: 10px;
}

.header-profile-section .user-profile-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}

.header-profile-section .user-profile-image img {
    width: 100%;
    height: 100%;
}

.profile-dropdown {
    position: absolute;
    color: #fff;
    background-color: var(--header-bg-color);
    width: 100%;
    top: calc(100% + 5px);
}

.logout-button {
    background-color: #25282e;
    padding: 10px 5px;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #2f3238;
}

.swal2-textarea {
    height: 100px;
    resize: none;
}
/* login css */
.login-container {
    padding: var(--main-container-padding);
    display: flex;
    background-image: radial-gradient(circle at bottom right, rgba(248, 172, 255, 0.9) -100%, var(--body-bg-color) 30%);
    position: relative;
    z-index: 2;
    align-items: center;
    justify-content: space-evenly;
}

.login-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: radial-gradient(circle at top left, #60efff -100%, transparent 40%);
    z-index: -1;
}

.login-right-section {
    position: relative;
    height: 350px;
}

.login-right-section .feature-image {
    overflow: hidden;
    width: 100%;
    border-radius: 20px;
    height: 100%;
}

.image-shadow {
    border-radius: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10px;
    right: 10px;
    background-image: linear-gradient(80deg, #696eff, #f8acff);
    z-index: -1;
}

.login-right-section .feature-image img {
    width: 100%;
    height: 100%;
}

.login-left-section {
    width: 35%;
    height: 350px;
    display: flex;
    align-items: center;
}

.form-section {
    width: 100%;
    height: 100%;
    height: fit-content;
    color: #fff;
}

.form-header {
    margin-bottom: 10px;
}

.form-header h1 {
    margin-bottom: 15px;
}

.login-input input {
    background-color: transparent;
    padding: 15px;
    margin-top: 10px;
    color: #d8d8d8;
    border-radius: 4px;
}

.login-input input::placeholder {
    color: rgb(199, 199, 199);
}
.wallet-profile-section {
    display: flex;
    align-items: center;
}
.wallet-button {
    color: #d8d8d8;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    margin-right: 15px;
    background-color: transparent;
    background-image: linear-gradient(to right, #696eff, #f8acff);
    padding: 5px 15px;
    border-radius: 4px;
}
.login-btn {
    background-color: transparent;
    background-image: linear-gradient(to right, #696eff, #f8acff);
    padding: 15px 0;
    color: #fff;
    font-weight: 500;
    margin-top: 10px;
    border-radius: 4px;
    font-size: 15px;
}

.reset-password {
    margin-top: 10px;
}

.reset-password a {
    font-size: 13px;
    color: #fff;
}

.check-mail-container {
    display: flex;
    justify-content: center;
}

.check-mail-container .check-mail {
    color: #fff;
    background-color: #181f30;
    height: 50vh;
    display: flex;
    padding: 0 25px;
    align-items: center;
    font-size: 30px;
}

.continue-to-dashboard {
    color: #fff;
    background-color: #181f30;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    font-size: 30px;
}

/* dashboard */
.dashboard-content {
    padding: var(--main-container-padding);
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: var(--body-bg-color);
}

.dashboard-header {
    display: flex;
    justify-content: center;
}

.dashboard-header h1 {
    background: var(--font-gradient);
    background-clip: text;
    text-align: center;
    -webkit-text-fill-color: transparent;
}
.assets-border-container {
    margin-top: 30px;
    margin-bottom: 30px;
}
.assets-border {
    color: #ffff;
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    display: flex;
    justify-content: space-around;
    margin: 25px 0px;
}

.link-btn {
    padding: 5px 10px;
    text-decoration: none;
    font-size: 14px;
}

/* product view */
.product-cards-section {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 35px !important;
}

.product-card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 350px;
    min-height: 450px;
    height: fit-content;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    position: relative;
    /* z-index: 11; */
    /* overflow: hidden; */
}

.top-left-border {
    width: 80px;
    height: 120px;
    border-left: #fff 3px solid;
    border-top: #fff 3px solid;
    border-top-left-radius: 10px;
    position: absolute;
    top: -2px;
    left: -2px;
}

.top-right-border {
    width: 80px;
    height: 120px;
    border-right: #fff 3px solid;
    border-top: #fff 3px solid;
    border-top-right-radius: 10px;
    position: absolute;
    top: -2px;
    right: -2px;
}

.bottom-left-border {
    width: 80px;
    height: 120px;
    border-left: #fff 3px solid;
    border-bottom: #fff 3px solid;
    border-bottom-left-radius: 10px;
    position: absolute;
    bottom: -2px;
    left: -2px;
}

.bottom-right-border {
    width: 80px;
    height: 120px;
    border-right: #fff 3px solid;
    border-bottom: #fff 3px solid;
    border-bottom-right-radius: 10px;
    position: absolute;
    bottom: -2px;
    right: -2px;
}

.product-card .profile-section {
    margin: 10px 15px;
    display: flex;
    /* width: 90%; */
    align-items: center;
    justify-content: space-between;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-and-image {
    display: flex;
    align-items: center;
    width: 60%;
}

.product-card .profile-section .author-profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid white;
    margin-right: 5px;
}

.product-card .profile-section .author-profile-image img {
    width: 100%;
    height: 100%;
}

.product-card .profile-section .author-name {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    color: #fff;
}

.product-card .profile-section .product-name {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    width: 40%;
    margin-right: 4px;
    text-align: end;
}

.product-card .profile-section .product-name p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-card .image-section {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 315px;
}

.product-card .image-section img {
    width: 90%;
    height: 100%;
    border-radius: 6px;
}

.product-card .image-section .download-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 20px;
    left: 30px;
}

.no-products-found {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    text-align: center;
    color: #fff;
}

.player-section {
    background: radial-gradient(
        100% 1582.07% at 0% 50.56%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 52.08%,
        rgba(255, 255, 255, 0.162) 100%
    );
    margin: auto;
    margin-top: 10px;
    overflow: hidden;
    width: 100%;
    padding: 15px 0;
    border-radius: 4px;
    z-index: 3;
    min-height: 75px;
}

.player-controls {
    width: 95%;
    margin: auto;
}

/* Footer */
.footer-container {
    width: 100%;
    height: 153px;
    background: black;
    /* position: absolute; */
    padding: var(--main-container-padding);
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.footer-container .logo {
    width: 150px;
}

.footer-container .social-policy-section {
    color: #fff;
    display: flex;
    width: 30%;
    justify-content: space-evenly;
}

.design-by {
    margin-top: 15px;
    color: #6c6d6c;
}

.footer-container .social-policy-section a,
.two-doors-span a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}

.loader-container {
    width: 100%;
    height: 100vh;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
}

.loader-container::before {
    content: "";
    position: absolute;
    top: 0;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #ffff;
    z-index: -1;
    opacity: 0.5;
}

/* complete media queries */
@media (max-width: 1300px) {
    /* login css */
    .login-container {
        justify-content: space-between;
    }

    .login-left-section {
        width: 40%;
        height: 350px;
    }

    /* product view */
    .product-card {
        width: 300px;
        min-height: 410px;
    }

    .product-card .image-section {
        min-height: 250px;
    }

    /* footer */
    .footer-container .social-policy-section {
        width: 35%;
    }
}

@media (max-width: 1200px) {
    .header,
    .login-container,
    .footer-container,
    .dashboard-content {
        padding-left: 75px;
        padding-right: 75px;
    }
}

@media (max-width: 1150px) {
    .header,
    .login-container,
    .footer-container,
    .dashboard-content {
        padding-left: 55px;
        padding-right: 55px;
    }
}

@media (max-width: 1100px) {
    .header,
    .login-container,
    .footer-container,
    .dashboard-content {
        padding-left: 40px;
        padding-right: 40px;
    }

    /* login css */
    .login-left-section {
        width: 50%;
    }

    /* product view */
    .product-cards-section {
        grid-gap: 10px;
    }

    .product-card {
        width: 270px;
        min-height: 380px;
    }

    .product-card .image-section {
        min-height: 230px;
    }

    /* footer */
    .footer-container .social-policy-section {
        width: 45%;
    }
}

@media (max-width: 930px) {
    /* product view */
    .product-cards-section {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 830px) {
    .header,
    .login-container,
    .footer-container,
    .dashboard-content {
        padding-left: 45px;
        padding-right: 45px;
    }
}

@media (max-width: 730px) {
    /* login css */
    .login-container {
        justify-content: center;
    }

    .login-right-section {
        display: none;
    }

    .login-left-section {
        width: 100%;
    }

    /* product view */
    .product-cards-section {
        padding: 10px 0px;
        justify-content: center;
        grid-template-columns: 1fr;
    }

    .product-cards-section::-webkit-scrollbar {
        background-color: #25282e;
    }

    .product-cards-section {
        grid-gap: 10px;
    }

    .product-card {
        min-width: 270px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .product-card .image-section {
        min-height: 240px;
    }

    /* footer */
    .footer-container .social-policy-section {
        width: 65%;
    }
}

@media (max-width: 590px) {
    .assets-border {
        flex-direction: column;
        height: 80px;
    }
    .header,
    .login-container,
    .footer-container,
    .dashboard-content {
        padding-left: 15px;
        padding-right: 15px;
    }
    /* footer */
    .footer-container {
        flex-direction: column;
        height: fit-content;
        padding-bottom: 20px;
    }

    .footer-container .social-policy-section {
        width: 100%;
        flex-direction: column;
    }

    .footer-container .social-policy-section .policy,
    .footer-container .social-policy-section .social {
        margin-top: 15px;
        position: relative;
    }
}
